// wordsData.js

const wordsData = [
  { id: 1, word: "सः", iast: "saḥ", mean: "He", meanH: "वह" },
  { id: 2, word: "तौ", iast: "tau", mean: "Those two", meanH: "वे दो" },
  { id: 3, word: "ते", iast: "te", mean: "They", meanH: "वे" },
  { id: 4, word: "कः", iast: "kaḥ", mean: "Who", meanH: "कौन" },
  { id: 5, word: "ईश्वरः", iast: "īśvaraḥ", mean: "God", meanH: "ईश्वर" },
  { id: 6, word: "बालकः", iast: "bālakaḥ", mean: "Boy", meanH: "बालक" },
  { id: 7, word: "मनुष्यः", iast: "manuṣyaḥ", mean: "Human", meanH: "मनुष्य" },
  { id: 8, word: "नृपः", iast: "nṛpaḥ", mean: "King", meanH: "नृप" },
  { id: 9, word: "विद्यालयः", iast: "vidyālayaḥ", mean: "School", meanH: "विद्यालय" },
  { id: 10, word: "ग्रामः", iast: "grāmaḥ", mean: "Village", meanH: "ग्राम" },
  { id: 11, word: "त्वं", iast: "tvam", mean: "You", meanH: "तुम" },
  { id: 12, word: "अहं", iast: "aham", mean: "I", meanH: "मैं" },
  { id: 13, word: "अस्ति", iast: "asti", mean: "is/are/am", meanH: "है/हैं" },
  { id: 14, word: "न", iast: "na", mean: "not", meanH: "नहीं" },
  { id: 15, word: "अत्र", iast: "atra", mean: "here", meanH: "यहाँ" },
  { id: 16, word: "तत्र", iast: "tatra", mean: "there", meanH: "वहाँ" },
  { id: 17, word: "यत्र", iast: "yatra", mean: "where", meanH: "जहाँ" },
  { id: 18, word: "कुत्र", iast: "kutra", mean: "where", meanH: "कहाँ" },
  { id: 19, word: "किम्", iast: "kim", mean: "what", meanH: "क्या" },
  { id: 20, word: "सर्वत्र", iast: "sarvatra", mean: "everywhere", meanH: "हर जगह" },
  { id: 21, word: "गच्छति", iast: "gacchati", mean: "goes", meanH: "जाता/जाती/जाते हैं" },
  { id: 22, word: "गच्छसि", iast: "gacchasi", mean: "go", meanH: "जा" },
  { id: 23, word: "गच्छामि", iast: "gacchāmi", mean: "I go", meanH: "मैं जाता/जाती हूँ" },
  { id: 24, word: "नास्ति", iast: "nāsti", mean: "not there is/are/am not", meanH: "नहीं है/हैं" },
  { id: 25, word: "गृहं", iast: "gṛhaṁ", mean: "home", meanH: "घर" },
  { id: 26, word: "नगरम्", iast: "nagaram", mean: "city", meanH: "नगर" },
  { id: 27, word: "ग्रामं", iast: "grāmaṁ", mean: "village", meanH: "गाँव" },
  { id: 28, word: "भू", iast: "bhū", mean: "earth", meanH: "पृथ्वी" },
  { id: 29, word: "पठ्", iast: "paṭh", mean: "read", meanH: "पढ़" },
  { id: 30, word: "गम्", iast: "gam", mean: "go", meanH: "जा" },
  { id: 31, word: "हस्", iast: "has", mean: "laugh", meanH: "हंस" },
  { id: 32, word: "आपणम्", iast: "āpaṇam", mean: "water", meanH: "पानी" },
  { id: 33, word: "पाठशालाम्", iast: "pāṭhaśālām", mean: "classroom", meanH: "पाठशाला" },
  { id: 34, word: "इदानीम्", iast: "idānīm", mean: "now", meanH: "अब" },
  { id: 35, word: "यदा", iast: "yadā", mean: "when", meanH: "कब" },
  { id: 36, word: "कदा", iast: "kadā", mean: "when", meanH: "कब" },
  { id: 37, word: "सदा", iast: "sadā", mean: "always", meanH: "हमेशा" },
  { id: 38, word: "तदा", iast: "tadā", mean: "then", meanH: "तब" },
  { id: 39, word: "सर्वदा", iast: "sarvadā", mean: "always", meanH: "हमेशा" },
  { id: 40, word: "सदैव", iast: "sadāiva", mean: "always", meanH: "हमेशा" },
  { id: 41, word: "पुस्तकम्", iast: "pustakam", mean: "book", meanH: "पुस्तक" },
  { id: 42, word: "पुष्पम्", iast: "puṣpam", mean: "flower", meanH: "पुष्प" },
  { id: 43, word: "जलम्", iast: "jalam", mean: "water", meanH: "जल" },
  { id: 44, word: "सत्यम्", iast: "satyam", mean: "truth", meanH: "सत्य" },
  { id: 45, word: "भोजनम्", iast: "bhojanam", mean: "food", meanH: "भोजन" },
  { id: 46, word: "राज्यम्", iast: "rājyam", mean: "kingdom", meanH: "राज्य" },
  { id: 47, word: "उद्यानम्", iast: "udyānam", mean: "garden", meanH: "उद्यान" },
  { id: 48, word: "रक्ष्", iast: "rakṣ", mean: "protect", meanH: "रक्षा" },
  { id: 49, word: "वद्", iast: "vad", mean: "speak", meanH: "बोलना" },
  { id: 50, word: "पच्", iast: "pac", mean: "cook", meanH: "पकाना" },
  { id: 51, word: "नम्", iast: "nam", mean: "bow", meanH: "नमन" },
  { id: 52, word: "अद्य", iast: "adya", mean: "today", meanH: "आज" },
  { id: 53, word: "सायम्", iast: "sāyam", mean: "evening", meanH: "शाम" },
  { id: 54, word: "प्रातः", iast: "prātaḥ", mean: "morning", meanH: "प्रातः" },
  { id: 55, word: "रात्रौ", iast: "rātrau", mean: "night", meanH: "रात्र" },
  { id: 56, word: "श्वः", iast: "śvaḥ", mean: "tomorrow", meanH: "कल (अगला)" },
  { id: 57, word: "परश्वः", iast: "paraśvaḥ", mean: "day after tomorrow", meanH: "परसों" },
  { id: 58, word: "दिवा", iast: "divā", mean: "during the day", meanH: "दिन में" },
  { id: 59, word: "मध्याह्ने", iast: "madhyāhne", mean: "at noon", meanH: "मध्याह्न" },
  { id: 60, word: "ह्यः", iast: "hyaḥ", mean: "yesterday", meanH: "कल (पिछला)" },
  { id: 61, word: "ज्ञानम्", iast: "jñānam", mean: "knowledge", meanH: "ज्ञान" },
  { id: 62, word: "यदि", iast: "yadi", mean: "if", meanH: "अगर" },
  { id: 63, word: "तर्हि", iast: "tarhi", mean: "then", meanH: "तब" },
  { id: 64, word: "गमिष्यसि", iast: "gamiṣyasi", mean: "you will go", meanH: "तुम जाओगे" },
  { id: 65, word: "गमिष्यति", iast: "gamiṣyati", mean: "he/she/it will go", meanH: "वह जाएगा/जाएगी/जाएगे" },
  { id: 66, word: "यथा", iast: "yathā", mean: "as", meanH: "जैसा" },
  { id: 67, word: "तथा", iast: "tathā", mean: "so", meanH: "ऐसा ही" },
  { id: 68, word: "कथम्", iast: "katham", mean: "how", meanH: "कैसे" },
  { id: 69, word: "गमिष्यामि", iast: "gamiṣyāmi", mean: "I will go", meanH: "मैं जाऊँगा/जाऊँगी" },
  { id: 70, word: "बालिका", iast: "bālikā", mean: "Girl", meanH: "बालिका" },
  { id: 71, word: "लता", iast: "latā", mean: "Creeper", meanH: "लता" },
  { id: 72, word: "कथा", iast: "kathā", mean: "Story", meanH: "कहानी" },
  { id: 73, word: "क्रीडा", iast: "krīḍā", mean: "Play", meanH: "खेल" },
  { id: 74, word: "पाठशाला", iast: "pāṭhaśālā", mean: "Classroom", meanH: "पाठशाला" },
  { id: 75, word: "विद्या", iast: "vidyā", mean: "Knowledge", meanH: "विद्या" },
  { id: 76, word: "आगम्", iast: "āgam", mean: "Come", meanH: "आओ" },
  { id: 77, word: "दृश्", iast: "dṛś", mean: "See", meanH: "देख" },
  { id: 78, word: "स्था", iast: "sthā", mean: "Stay", meanH: "रह" },
  { id: 79, word: "पा", iast: "pā", mean: "Drink", meanH: "पीना" },
  { id: 80, word: "घ्रा", iast: "ghrā", mean: "Smell", meanH: "सूंघना" },
  { id: 81, word: "सद्", iast: "sad", mean: "Sit", meanH: "बैठ" },
  { id: 82, word: "इतः", iast: "itaḥ", mean: "From here", meanH: "यहां से" },
  { id: 83, word: "ततः", iast: "tataḥ", mean: "From there", meanH: "वहां से" },
  { id: 84, word: "यतः", iast: "yataḥ", mean: "From where", meanH: "जहां से" },
  { id: 85, word: "कुतः", iast: "kutaḥ", mean: "Where from", meanH: "कहां से" },
]
  
  export default wordsData;
  