// Stories.js
import React from 'react';
import './Stories.css';

function Stories() {
  return (
    <div className="stories">
      <h1>Stories</h1>
      <div className="video-list">
        {/* Video 1 */}
        <div className="video-item">
        <p><strong>Level1-01</strong> Raju's field | राजोः क्षेत्रम्</p>
          <iframe
            title="L1-01"
            width="250"
            height="141"
            src="https://www.youtube.com/embed/TyW7J52_Lvk?si=r90Lt02eFTZlFCKi"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>

        {/* Video 2 */}
        <div className="video-item">
        <p>Video 2 Title</p>
          <iframe
            title="L1-02"
            width="250"
            height="141"
            src="https://www.youtube.com/embed/TyW7J52_Lvk?si=r90Lt02eFTZlFCKi"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>

        {/* Add more videos as needed */}
      </div>
    </div>
  );
}

export default Stories;
