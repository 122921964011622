// Layout.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Layout.css';

function Layout({ children }) {
  return (
    <div className="layout">
      <header className="navbar">
        <div className="logo">
          <Link to="/">
            <img src="/logo.png" alt="Logo" />
          </Link>
        </div>
        <nav>
          <ul>
            {/*
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/contact">Contact</Link></li>
           */}
          </ul>
        </nav>
      </header>

      <main className="main-content">
        {children}
      </main>
      {/* Footer or other shared components */}
    </div>
  );
}

export default Layout;
