import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import LandingPage from './LandingPage';
import AboutPage from './AboutPage';
import ServicesPage from './ServicesPage';
import ContactPage from './ContactPage';
import WordsActivity from './WordsActivity';
import Stories from './Stories';
import './App.css';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/words-activity" element={<WordsActivity />} />
          <Route path="/stories" element={<Stories />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
