// LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="landing-page">
      <main className="main-content">
        <h1>Welcome to iamsanskrit.com</h1>
        {/*}
        <div className="navigation-buttons">
          <Link to="/about"><button>About</button></Link>
          <Link to="/services"><button>Services</button></Link>
          <Link to="/contact"><button>Contact</button></Link>
        </div>
        */}
        <p style={{ marginTop: '20px' }}>Choose an activity below to learn or practice</p>

        <div className="activityA-buttons">
          <Link to="/stories"><button>Stories</button></Link>
          <Link to="/"><button>Conversations</button></Link>
          {/* Add more buttons for various activities */}
        </div>
        <div className="activityB-buttons">
          <Link to="/words-activity"><button>Words</button></Link>
          <Link to="/"><button>Shabd-roop</button></Link>
          <Link to="/"><button>Dhatu-roop</button></Link>

          {/* Add more buttons for various activities */}
        </div>
      </main>
    </div>
  );
}

export default LandingPage;
