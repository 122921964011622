import React, { useState } from 'react';
import './WordsActivity.css';
import wordsData from './wordsData';

function WordsActivity() {
  const [selectedWords, setSelectedWords] = useState([]);
  const [currentWord, setCurrentWord] = useState(null);
  const [showActivity, setShowActivity] = useState(false);
  const [options, setOptions] = useState([]);
  const [clickedOption, setClickedOption] = useState(null);
  const [optionsClicked, setOptionsClicked] = useState(false);
  const [completedRounds, setCompletedRounds] = useState(0);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);


  const handleButtonClick = (num) => {
    const selected = wordsData.slice(0, num);
    setSelectedWords(selected);
    setCurrentWord(null);
    setShowActivity(false);
  };

  const colorMapping = {
    word: 'black',
    iast: 'grey',
    mean: 'black',
    meanH: 'orange',
  };


  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const getRandomOptions = (currentWord, wordsList) => {
    const filteredList = wordsList.filter(word => word.id !== currentWord.id);

    const randomWords = [];
    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * filteredList.length);
      randomWords.push(filteredList[randomIndex]);
      filteredList.splice(randomIndex, 1);
    }

    const incorrectOptions = randomWords.map(word => [word.mean, word.meanH]);

    const shuffledOptions = shuffleArray([
      [currentWord.mean, currentWord.meanH],
      ...incorrectOptions
    ]);
    setOptions(shuffledOptions);
  };

  const handleStartButtonClick = () => {
    const randomIndex = Math.floor(Math.random() * selectedWords.length);
    const initialWord = selectedWords[randomIndex];

    getRandomOptions(initialWord, selectedWords);

    setCurrentWord({ ...initialWord, showMeanings: false });
    setShowActivity(true);
    setOptionsClicked(false);
    setCompletedRounds(0);
    setCorrectAnswersCount(0);

  };

  const handleBackButtonClick = () => {
    setSelectedWords([]);
    setShowActivity(false);
  };

  const handleOptionClick = (meaning, meaningH) => {
    // Check if the clicked option is the correct meaning
    const isCorrect = meaning === currentWord.mean && meaningH === currentWord.meanH;

    // Update the count of correct answers
    if (isCorrect) {
      setCorrectAnswersCount(count => count + 1);
    }

    // Update the UI based on correctness for all options
    setOptions((prevOptions) =>
      prevOptions.map(([prevMeaning, prevMeaningH]) => {
        const optionClass = isCorrect && meaning === prevMeaning && meaningH === prevMeaningH ? 'correct-answer' : !isCorrect && currentWord.mean === prevMeaning && currentWord.meanH === prevMeaningH ? 'correct-answer' : 'incorrect-answer';
        return [prevMeaning, prevMeaningH, optionClass];
      })
    );

    // Disable the buttons after an option is clicked
    setOptionsClicked(true);
  };

  const handleNextWordClick = () => {
    const randomIndex = Math.floor(Math.random() * selectedWords.length);
    const nextWord = selectedWords[randomIndex];

    getRandomOptions(nextWord, selectedWords);

    setCurrentWord({ ...nextWord, showMeanings: false });

    // Clear the clicked option when moving to the next word
    setClickedOption(null);

    // Enable the buttons for the next word
    setOptionsClicked(false);

    // Increase the completed rounds after each round
    setCompletedRounds((prevRounds) => Math.min(prevRounds + 1, 10));
  };

  return (
    <div>
      {!showActivity && (
        <>
          <h2>Words Activity</h2>
          <p>How many words would you like to practice with?</p>
          <div className="wactivity-buttons">
            <button onClick={() => handleButtonClick(10)}>10</button>
            <button onClick={() => handleButtonClick(20)}>20</button>
            <button onClick={() => handleButtonClick(30)}>30</button>
            <button onClick={() => handleButtonClick(40)}>40</button>
            <button onClick={() => handleButtonClick(50)}>50</button>
            <button onClick={() => handleButtonClick(60)}>60</button>
            <button onClick={() => handleButtonClick(70)}>70</button>
            <button onClick={() => handleButtonClick(85)}>85</button>
          </div>
          {selectedWords.length > 0 && (
            <div>
              <button className="button-go" onClick={handleStartButtonClick}>Go!</button>
            </div>
          )}

          {selectedWords.length > 0 && (
            <div>
              <h3>Selected Words:</h3>
              <ul>
                {selectedWords.map((word) => (
                  <li key={word.id}>
                    <strong style={{ color: colorMapping.word }}>{word.word}</strong> {<span style={{ color: colorMapping.iast }}>({word.iast})</span>} - <span style={{ color: colorMapping.mean }}>{word.mean}</span> / <span style={{ color: colorMapping.meanH }}>{word.meanH}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

        </>
      )}

      {/* Display the current word and options during the activity */}

      {showActivity && currentWord && (
        <div>
          <div className="activityB-buttons">
            <button className="button-back" onClick={handleBackButtonClick}>Back</button>
          </div>
          {completedRounds < 10 && (
            <p className="current-word">{currentWord.word}</p>
          )}

          {/* Display options */}
          {completedRounds < 10 && (
            <div className="options">
              {options.map(([meaning, meaningH, correctClass], index) => (
                <button
                  key={index}
                  className={`option-button ${correctClass}`}
                  onClick={() => handleOptionClick(meaning, meaningH)}
                  disabled={optionsClicked}
                >
                  {`${meaning}, ${meaningH}`}
                </button>
              ))}
            </div>
          )}

          <div className="container">
            {/*<div className="horizontal-line"></div>*/}

            {/* Display squares for progress */}
            <div className="progress-squares">
              {[...Array(10)].map((_, index) => (
                <div key={index} className={`progress-square ${index < completedRounds ? 'completed' : ''}`}></div>
              ))}
            </div>
          </div>

          {completedRounds < 10 && (
            <div className="activityB-buttons">
              <button className="button-next" onClick={handleNextWordClick}>New Word</button>
            </div>
          )}

          {completedRounds === 10 && (
            <>
              <p><strong>Correct Answers:</strong></p>
              <p className='score'>{correctAnswersCount}/10</p>
            </>
          )}


        </div>
      )}
    </div>
  );
}

export default WordsActivity;
